<template>
    <div class="one-temp-housing template mb-5" :class="[viewingFrom]">
        <div class="main-content">
            <header class="relative p-5 m-0">
                <div class="building-headline">
                    <h1 class="font-bold">{{ buildingTitle }}</h1>
                    <div class="flex flex-row items-center mb-3">
                        <r-icon
                            id="locationMarker"
                            class="mb-0"
                            icon-style="fad"
                            name="map-marker-alt"
                            color="000"
                            size="lg"
                        >
                        </r-icon>
                        <span class="pl-1 text-lg">
                            {{ address }} {{ city ? `${city},` : '' }} {{ state ? state : '' }}
                            {{ country ? country : '' }} {{ zip ? zip : '' }}
                        </span>
                    </div>
                </div>

                <div class="images">
                    <div class="keen-slider image-slider" ref="images_slider">
                        <div class="keen-slider__slide lazy__slide pointer" v-for="(src, idx) in gallery" :key="idx">
                            <img class="rounded shadow-md" :src="loaded[idx] ? src : ''" />
                        </div>
                    </div>
                </div>
                <div v-if="buildingUnits?.length == 0 || !buildingUnits">
                    <a class="check-availability mt-8 inline-block w-full text-center" :href="buildingSkuUrl()">
                        <span>Request Availability</span>
                    </a>
                </div>
            </header>
            <div class="details p-5 m-0">
                <div v-if="buildingUnits && buildingUnits.length > 0" class="building-units mb-8">
                    <h1 class="font-bold mb-3">Units</h1>
                    <div class="keen-slider unit-slider" ref="unit_slider">
                        <div
                            class="keen-slider__slide unit border rounded border-black px-3 py-4"
                            v-for="(unit, idx) in buildingUnits"
                            :key="idx"
                        >
                            <div class="unit__info">
                                <p class="building-name">{{ buildingTitle }}</p>
                                <p class="address">
                                    {{ rowProperty('properties.temporary-housing-details-name', '', unit) }}
                                </p>
                            </div>
                            <div class="unit__bedbath flex justify-between items-center">
                                <div class="beds">
                                    <r-icon class="mb-0" icon-style="fad" name="bed-alt" color="000" size="lg">
                                    </r-icon>
                                    <span class="pl-1 text-lg">
                                        {{ unitBeds(unit) }}
                                    </span>
                                </div>
                                <div class="baths">
                                    <r-icon class="mb-0" icon-style="fad" name="bath" color="000" size="lg"> </r-icon>
                                    <span class="pl-1 text-lg">
                                        {{ unitBaths(unit) }}
                                    </span>
                                </div>
                                <a class="check-availability" :href="unitSkuUrl(unit)">
                                    <span>Request Availability</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="buildingAmenities.length > 0" class="features my-8">
                    <h1 class="font-bold mb-3">Features</h1>
                    <div class="my-4">
                        <ul class="list-disc list-inside">
                            <li v-for="amenity in buildingAmenities" :key="amenity">{{ amenity }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="additionalInformation" class="bg-black text-white p-5">
                <h1 class="font-bold">Additional Information</h1>

                <div
                    v-for="section in additionalInformation"
                    :key="section.title"
                    class="border-solid border-1 border-gray rounded text-white p-5 my-4"
                >
                    <h3 class="text-lg font-bold text-center mb-2">{{ section.title }}</h3>
                    {{ section.content }}
                </div>
            </div>

            <div id="links" class="p-5 w-full" v-if="links.length > 0">
                <h1 class="font-bold">Links</h1>

                <a
                    v-for="link in links"
                    :key="link.title"
                    class="block border-solid border-1 border-black rounded p-3 my-4 w-full flex flex-row justify-between items-center hover:no-underline"
                    :href="link.url"
                    target="_blank"
                >
                    <span class="mb-0 text-base truncate">
                        {{ link.title }}
                        <br />
                        <span class="text-xs text-black truncate">{{ link.url }}</span>
                    </span>
                    <r-icon name="chevron-right" iconStyle="fal" size="2x"></r-icon>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { layerDetails } from 'mixins';
import { filter, merge } from 'utils/lodash';
import { mapActions } from 'vuex';
import qs from 'qs';

export default {
    mixins: [layerDetails],
    components: {},
    props: {
        record: {
            type: Object,
            required: true,
        },
        viewingFrom: {
            type: String, // "map" or "standalone"
            required: true,
        },
    },
    data: () => ({
        galleryIdx: 0,
        currencyFormatter: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }),
        loaded: [],
        imageSlider: null,
        unitSlider: null,
        children: [],
    }),
    async mounted() {
        this.buildImagesSlider();

        const res = await this.search({
            category: this.record.properties.category_slug,
            query: this.searchQueryParams(),
        });

        this.children = res.features;
        this.$nextTick(() => this.buildUnitsSlider());
    },
    beforeDestroy() {
        if (this.imageSlider) {
            this.imageSlider.destroy();
        }
        if (this.unitSlider) {
            this.unitSlider.destroy();
        }
    },
    computed: {
        row() {
            return this.record;
        },
        recordId() {
            return this.record.id;
        },
        buildingId() {
            return this.getPropOrFalse('temporary-housing-details-building-id');
        },
        props() {
            return this.record.properties;
        },
        gallery() {
            return this.rowProperty('properties.temporary-housing-building-images');
        },
        buildingTitle() {
            return this.getPropOrFalse('temporary-housing-details-building-name');
        },
        buildingUnits() {
            return this.children;
        },
        additionalInformation() {
            const additionalInformation = [
                {
                    title: 'Description',
                    content: this.description,
                },
                {
                    title: 'Directions',
                    content: this.directions,
                },
                {
                    title: 'Availability',
                    content: this.availability,
                },
                {
                    title: 'Pets',
                    content: this.pets,
                },
                {
                    title: 'Smoking',
                    content: this.smoking,
                },
                {
                    title: 'Parking',
                    content: this.parking,
                },
            ];

            return filter(additionalInformation, (section) => section.content);
        },
        links() {
            const links = [
                {
                    title: 'Website',
                    url: this.getPropOrFalse('temporary-housing-building-url'),
                },
                {
                    title: 'Property Map',
                    url: this.getPropOrFalse('temporary-housing-details-property-map'),
                },
            ];

            return filter(links, (link) => link.url);
        },
        availability() {
            return this.getPropOrFalse('temporary-housing-details-availability');
        },
        directions() {
            return this.getPropOrFalse('temporary-housing-details-directions');
        },
        pets() {
            return this.getPropOrFalse('temporary-housing-details-pets');
        },
        smoking() {
            return this.getPropOrFalse('temporary-housing-details-smoking');
        },
        parking() {
            return this.getPropOrFalse('temporary-housing-details-parking');
        },
        description() {
            return this.getPropOrFalse('temporary-housing-building-description');
        },
        address() {
            return this.getPropOrFalse('temporary-housing-location-address');
        },
        city() {
            return this.getPropOrFalse('temporary-housing-location-city');
        },
        state() {
            return this.getPropOrFalse('temporary-housing-location-state');
        },
        country() {
            return this.getPropOrFalse('temporary-housing-location-country');
        },
        zip() {
            return this.getPropOrFalse('temporary-housing-location-zip');
        },
        features() {
            return this.getPropOrFalse('temporary-housing-unit-amenities');
        },
        buildingAmenities() {
            return this.getPropOrFalse('temporary-housing-building-amenities-list');
        },
        petFriendly() {
            return this.getPropOrFalse('temporary-housing-pet-friendly');
        },
        uniqueId() {
            return this.getPropOrFalse('unique_id');
        },
        currency() {
            return this.getPropOr('temporary-housing-unit-currency', 'USD');
        },
        amenities() {
            const amenities = [];

            if (this.petFriendly) {
                amenities.push({
                    name: 'Pet Friendly',
                    icon: 'paw-alt',
                    iconStyle: 'fad',
                });
            }

            return amenities;
        },
        numGalleryImages() {
            return this.gallery.length;
        },
        isRental() {
            return this.props.status === 'For Rent';
        },
        pricingInfoHTML() {
            return this.isRental ? `Rent ${this.price}<span>/month</span>` : this.price;
        },
        price() {
            // if the price is pre-formatted, leave it alone. Otherwise, format it
            return isNaN(parseFloat(this.props.price))
                ? this.props.price
                : this.currencyFormatter.format(parseFloat(this.props.price));
        },
        recordFilters() {
            // map has filters passed in via geojson object
            if (this.viewingFrom === 'map') {
                return this.rowProperty('properties.filters');
            }

            // standalone has filters passed in via URL
            return qs.parse(window.location.search.substring(1));
        },
        skuId() {
            return this.getPropOrFalse('temporary-housing-sku-id');
        },
    },
    methods: {
        ...mapActions('records', ['getGeoJSON', 'search']),
        searchQueryParams() {
            const resultsQuery = { results: 100, geojson: 1 };

            if (this.hasPolygon) {
                return merge({}, this.filtersQuery(), resultsQuery);
            }

            return merge({}, this.filtersQuery(), resultsQuery);
        },
        filtersQuery() {
            const parentFilter = { parentUniqueId: this.uniqueId };
            const filters = merge({}, parentFilter, this.recordFilters);

            return { filter: filters };
        },

        unitSkuUrl(buildingUnit) {
            const unitId = buildingUnit.properties['temporary-housing-unit-id'];
            const beds = buildingUnit.properties['temporary-housing-unit-bedrooms'];
            const baths = buildingUnit.properties['temporary-housing-unit-bathrooms'];
            const city = this.city ? `, ${this.city}` : '';
            const address = `${this.address}${city}`;
            const options = encodeURIComponent(
                JSON.stringify({
                    building_id: this.buildingId,
                    unit_id: unitId,
                    num_beds: beds,
                    num_baths: baths,
                    pet_friendly: this.petFriendly,
                    building_title: this.buildingTitle,
                    address: address,
                    currency: this.currency,
                })
            );
            return `relocity://sku?id=${this.skuId}&type=lead&options=${options}`;
        },
        buildingSkuUrl() {
            const city = this.city ? `, ${this.city}` : '';
            const address = `${this.address}${city}`;
            const options = encodeURIComponent(
                JSON.stringify({
                    building_id: this.buildingId,
                    unit_id: 0,
                    num_beds: 1,
                    num_baths: 1,
                    pet_friendly: false,
                    building_title: this.buildingTitle,
                    address: address,
                    currency: this.currency,
                })
            );
            return `relocity://sku?id=${this.skuId}&type=lead&options=${options}`;
        },
        buildImagesSlider() {
            if (this.imageSlider) {
                this.imageSlider.destroy();
            }

            this.imageSlider = new KeenSlider(this.$refs.images_slider, {
                afterChange: (s) => {
                    const idx = s.details().relativeSlide;

                    if (isNaN(idx)) {
                        return;
                    }

                    this.galleryIdx = idx;

                    // load in three images
                    this.loaded[this.galleryIdx] = true;
                    this.loaded[this.galleryIdx + 1] = true;
                    this.loaded[this.galleryIdx + 2] = true;

                    this.$forceUpdate();
                },
                loop: true,
                initial: 0,
                slidesPerView: 1.15,
                spacing: 20,
            });
        },
        buildUnitsSlider() {
            if (this.unitSlider) {
                this.unitSlider.destroy();
            }

            this.unitSlider = new KeenSlider(this.$refs.unit_slider, {
                afterChange: () => {
                    this.$forceUpdate();
                },
                loop: true,
                initial: 0,
                slidesPerView: 1.15,
                spacing: 20,
                options: {
                    size: 120,
                },
            });

            window.slider = this.unitSlider;
        },
        getPropOr(property, defaultResult = null) {
            const propertyPath = 'properties.' + property;
            return this.rowProperty(propertyPath, defaultResult);
        },
        getPropOrFalse(property) {
            const propertyPath = 'properties.' + property;
            return this.rowProperty(propertyPath, null) === null ? false : this.rowProperty(propertyPath);
        },
        unitBeds(unit) {
            const numBeds = this.rowProperty('properties.temporary-housing-unit-bedrooms', '', unit);
            return numBeds == 1 ? `${numBeds} Bed` : `${numBeds} Beds`;
        },
        unitBaths(unit) {
            const numBaths = this.rowProperty('properties.temporary-housing-unit-bathrooms', '', unit);
            return numBaths == 1 ? `${numBaths} Bath` : `${numBaths} Baths`;
        },
        onSeeFirstAvailable() {
            this.$el.querySelector('.availability-calendar.available').scrollIntoView({ behavior: 'smooth' });
        },
    },
};
</script>

<style>
.lazy__slide {
    height: 50vw;
    background-color: white;
    background-image: url('https://keen-slider.io/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    min-height: auto;
}

@media (min-width: 768px) {
    .lazy__slide {
        height: 200px;
    }
}

.lazy__slide img {
    background-color: transparent;
    width: auto;
    height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
}
</style>

<style lang="scss" scoped>
.one-temp-housing.template {
    .check-availability {
        padding: 10px;
        background-color: black;
        border-radius: 5px;

        span {
            color: white;
            font-size: 11px;
        }

        &:hover {
            background-color: #333;
            text-decoration: none;
        }
    }
    #additionalInformation {
        cursor: default !important;

        .border-gray {
            border-color: gray;
        }
    }

    /* Slider */
    .keen-slider {
        &.unit-slider {
            height: auto;

            .unit {
                .unit__info {
                    .building-name {
                        color: #9b9b9b;
                        font-size: 15px;
                    }
                    .address {
                        color: black;
                    }
                }

                .unit__bedbath {
                    margin-top: 20px;
                    .beds,
                    .baths {
                        span {
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    /* Details */
    .details {
        cursor: default !important;

        .address {
            font-size: 18px;
            line-height: 25px;
            color: #505152;
        }
    }

    #map {
        height: 200px;
    }

    #marker {
        background-color: #22c17a;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px 15px;
        border: 1px solid white;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(50% - 5px);
            width: 0;
            border-top: 5px solid #22c17a;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }
}
</style>
